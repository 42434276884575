// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-edit-js": () => import("./../../../src/pages/about/edit.js" /* webpackChunkName: "component---src-pages-about-edit-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-ar-js": () => import("./../../../src/pages/ar.js" /* webpackChunkName: "component---src-pages-ar-js" */),
  "component---src-pages-article-[slug]-js": () => import("./../../../src/pages/article/[slug].js" /* webpackChunkName: "component---src-pages-article-[slug]-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-physical-js": () => import("./../../../src/pages/events-physical.js" /* webpackChunkName: "component---src-pages-events-physical-js" */),
  "component---src-pages-events-virtual-js": () => import("./../../../src/pages/events-virtual.js" /* webpackChunkName: "component---src-pages-events-virtual-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-km-urx-article-[slug]-js": () => import("./../../../src/pages/kmUrx/article/[slug].js" /* webpackChunkName: "component---src-pages-km-urx-article-[slug]-js" */),
  "component---src-pages-km-urx-index-js": () => import("./../../../src/pages/kmUrx/index.js" /* webpackChunkName: "component---src-pages-km-urx-index-js" */),
  "component---src-pages-km-urx-news-js": () => import("./../../../src/pages/kmUrx/news.js" /* webpackChunkName: "component---src-pages-km-urx-news-js" */),
  "component---src-pages-km-urx-sign-js": () => import("./../../../src/pages/kmUrx/sign.js" /* webpackChunkName: "component---src-pages-km-urx-sign-js" */),
  "component---src-pages-live-performance-js": () => import("./../../../src/pages/live-performance.js" /* webpackChunkName: "component---src-pages-live-performance-js" */),
  "component---src-pages-modeling-js": () => import("./../../../src/pages/modeling.js" /* webpackChunkName: "component---src-pages-modeling-js" */),
  "component---src-pages-motion-capture-js": () => import("./../../../src/pages/motion-capture.js" /* webpackChunkName: "component---src-pages-motion-capture-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-scan-js": () => import("./../../../src/pages/scan.js" /* webpackChunkName: "component---src-pages-scan-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

